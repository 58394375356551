import {
  FirebaseApp,
  FirebaseError,
  FirebaseOptions,
  initializeApp
} from "firebase/app";
import {
  Firestore,
  addDoc,
  collection as collectionFire,
  connectFirestoreEmulator,
  Timestamp,
  query,
  where,
  onSnapshot,
  initializeFirestore,
  doc,
  getDocs as getDocsFirestore,
  setDoc
} from "firebase/firestore";
import {
  Auth,
  ErrorFn,
  NextOrObserver,
  Unsubscribe,
  User,
  connectAuthEmulator,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword
} from "firebase/auth";
import {
  FirebaseStorage,
  connectStorageEmulator,
  getStorage
} from "firebase/storage";
import { nanoid } from "nanoid";
import { Dashboard, Folder, Survey } from "interfaces";

class Fire {
  private _app: FirebaseApp;
  private _db: Firestore;
  private _auth: Auth;
  private _storage: FirebaseStorage;
  private _authObserverUnsubscribe?: Unsubscribe;

  constructor(config: FirebaseOptions, environment?: string) {
    this._app = initializeApp(config);

    this._db = initializeFirestore(this._app, {
      experimentalAutoDetectLongPolling: true
    });
    this._auth = getAuth(this._app);
    this._storage = getStorage(this._app);

    if (environment === "development") this.connectWithEmulator();
  }

  public signIn = (email: string, password: string) => {
    return signInWithEmailAndPassword(this._auth, email, password);
  };

  public signOut = () => {
    this.removeAuthObserver();

    return this._auth.signOut();
  };

  private addDoc = (coll: string, data: any) => {
    return addDoc(collectionFire(this._db, coll), data);
  };

  public getUser = () => {
    return this._auth.currentUser;
  };

  public setupAuthObserver = (
    observer: NextOrObserver<User>,
    error: ErrorFn
  ) => {
    this._authObserverUnsubscribe = onAuthStateChanged(
      this._auth,
      observer,
      error
    );
  };

  public removeAuthObserver = () => {
    if (this._authObserverUnsubscribe) this._authObserverUnsubscribe();
  };

  public createSurvey = async (props: {
    id: string;
    uid: string;
    shortId: string;
    folderId: string;
    name: string;
    description: string;
  }) => {
    const { id, uid, shortId, folderId, name, description } = props;

    const ts = Timestamp.now();

    const survey: Omit<Survey, "fireId"> = {
      id: `${shortId}/${id}`,
      created: ts,
      createdByUid: uid,
      edited: ts,
      editedByUid: uid,
      name: name,
      description: description,
      pages: [],
      folderId,
      meta: {
        nAnswers: 0
      }
    };

    let q = query(
      collectionFire(this._db, "surveys"),
      where("id", "==", `${shortId}/${id}`)
    );

    const checkDoc = await getDocsFirestore(q);
    if (checkDoc.size > 0)
      throw new Error(`Survey with id: ${id} already existz`);

    return this.addDoc("surveys", survey);
  };

  public createFolder = (props: { uid: string; name: string }) => {
    const { uid, name } = props;

    const ts = Timestamp.now();

    const folder: Omit<Folder, "fireId" | "id"> = {
      createdByUid: uid,
      created: ts,
      name: name,
      edited: ts,
      editedByUid: uid
    };

    return this.addDoc("folders", folder);
  };

  public sendAnswer = (props: {
    surveyFireId: string;
    surveyId: string;
    answers: { [key: string]: string };
    mapMarkers: {
      id: string;
      latitude: number;
      longitude: number;
      answers: { [id: string]: any };
      color?: string;
    }[];
  }) => {
    const { surveyId, answers, surveyFireId, mapMarkers } = props;

    const answer = {
      id: nanoid(),
      surveyId,
      surveyFireId,
      answers,
      mapMarkers,
      created: Timestamp.now()
    };

    return this.addDoc("answers", answer);
  };

  public subscribeToSurvey = (props: {
    shortId: string;
    id: string;
    onSuccess: (doc: Survey) => void;
    onError: (error: FirebaseError) => void;
  }) => {
    const { shortId, id, onSuccess, onError } = props;

    let q = query(
      collectionFire(this._db, "surveys"),
      where("id", "==", `${shortId}/${id}`),
      where("published", "==", true)
    );

    return onSnapshot(
      q,
      (snap) => {
        let entry: Survey | undefined = undefined;
        if (snap.size === 1) {
          snap.forEach((doc) => {
            entry = { ...doc.data(), fireId: doc.id } as Survey;
          });
        }

        if (entry) {
          onSuccess(entry);
        } else {
          onError(new Error("Document not found") as FirebaseError);
        }
      },
      (error) => {
        onError(error);
      }
    );
  };

  public subscribeToSurveys = (props: {
    uid: string;
    folderId: string;
    onSuccess: (docs: any[]) => void;
    onError: (error: FirebaseError) => void;
  }) => {
    const { uid, folderId, onSuccess, onError } = props;

    let q = query(
      collectionFire(this._db, "surveys"),
      where("createdByUid", "==", uid),
      where("folderId", "==", folderId)
    );

    return onSnapshot(
      q,
      (snap) => {
        const entries: any[] = [];
        if (snap.size > 0) {
          snap.forEach((doc) => {
            const data = doc.data();
            entries.push({ ...data, fireId: doc.id });
          });
        }
        onSuccess(entries);
      },
      (error) => {
        onError(error);
      }
    );
  };

  public subscribeToAnswers = (props: {
    id: string;
    shortId: string;
    onSuccess: (docs: any[]) => void;
    onError: (error: FirebaseError) => void;
  }) => {
    const { id, shortId, onSuccess, onError } = props;

    let q = query(
      collectionFire(this._db, "answers"),
      where("surveyId", "==", `${shortId}/${id}`)
    );

    return onSnapshot(
      q,
      (snap) => {
        const entries: any[] = [];
        if (snap.size > 0) {
          snap.forEach((doc) => {
            const data = doc.data();
            entries.push({ ...data, fireId: doc.id });
          });
        }
        onSuccess(entries);
      },
      (error) => {
        onError(error);
      }
    );
  };

  public subscribeToFolders = (props: {
    uid: string;
    onSuccess: (docs: Folder[]) => void;
    onError: (error: FirebaseError) => void;
  }) => {
    const { uid, onSuccess, onError } = props;

    let q = query(
      collectionFire(this._db, "folders"),
      where("createdByUid", "==", uid)
    );

    return onSnapshot(
      q,
      (snap) => {
        const entries: Folder[] = [];
        if (snap.size > 0) {
          snap.forEach((doc) => {
            const data = doc.data() as Omit<Folder, "fireId" | "id">;
            entries.push({ ...data, fireId: doc.id });
          });
        }
        onSuccess(entries);
      },
      (error) => {
        onError(error);
      }
    );
  };

  public getSurvey = async (props: { id: string }) => {
    const { id } = props;

    let q = query(collectionFire(this._db, "surveys"), where("id", "==", id));

    const checkDoc = await getDocsFirestore(q);

    if (checkDoc.size === 1) {
      const doc = checkDoc.docs[0];
      const docData = doc.data() as Survey;
      return { ...docData, fireId: doc.id };
    } else {
      throw new Error("Document not found");
    }
  };

  public setSurvey = async (survey: Survey) => {
    const { fireId, ...data } = survey;

    const newEditTime = Timestamp.now();

    return setDoc(doc(this._db, "surveys", fireId), {
      ...data,
      edited: newEditTime
    });
  };

  public saveDashboard = async (dashboard: Dashboard) => {
    const { fireId, ...data } = dashboard;

    const newEditTime = Timestamp.now();

    if (fireId === "") {
      return this.addDoc("dashboards", data);
    } else {
      return setDoc(doc(this._db, "dashboards", fireId), {
        ...data,
        edited: newEditTime
      });
    }
  };

  public getDashboard = async (props: {
    uid: string;
    surveyFireId: string;
  }) => {
    let q = query(
      collectionFire(this._db, "dashboards"),
      where("surveyFireId", "==", props.surveyFireId),
      where("createdByUid", "==", props.uid)
    );

    const checkDoc = await getDocsFirestore(q);

    if (checkDoc.size === 1) {
      const doc = checkDoc.docs[0];
      const docData = doc.data() as Dashboard;
      return { ...docData, fireId: doc.id };
    } else {
      return null;
    }
  };

  private connectWithEmulator = () => {
    connectFirestoreEmulator(this._db, "0.0.0.0", 8081);
    connectAuthEmulator(this._auth, "http://0.0.0.0:9098", {
      disableWarnings: true
    });
    connectStorageEmulator(this._storage, "http://0.0.0.0", 9198);
  };
}

export default Fire;
