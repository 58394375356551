import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { fire } from "services";
import { Timestamp, Unsubscribe } from "firebase/firestore";
import {
  convertFireAnswers,
  convertMarkersToGeoJsonExport,
  mapQuestionsToCsvMap,
  mapRespondentAnswers,
  sameDay
} from "./AnalyzePage.utils";
import {
  Dashboard,
  GraphVisualizer,
  MapVisualizer,
  Survey,
  VisualizerFire
} from "interfaces";
import AnalyzeProps, { FILTER_COMPARISON } from "./AnalyzePage.types";
import AnalyzeViewPage from "./AnalyzePage.view";
import { writeFileXLSX, utils } from "xlsx";
import { GRAPH_TYPE } from "./DataVisualizer/DataVisualizer.types";
import { Filter } from "./FilterModal/FilterModal.types";
import download from "downloadjs";
import FilterModal from "./FilterModal";
import GraphAddModal from "./GraphAddModal";
import MapAddModal from "./MapAddModal";
import { useImmer } from "use-immer";
import { VISUALIZATION_TYPE } from "enums";

const AnalyzePage = (props: AnalyzeProps) => {
  const { id, shortId } = useParams();

  const navigate = useNavigate();

  const unsubscribeAnswers = useRef<void | Unsubscribe>();
  const unsubscribeSurveys = useRef<void | Unsubscribe>();

  const [fireId, setFireId] = useState("");
  const [isOpenGraphAddModal, setIsOpenGraphAddModal] = useState(false);
  const [isOpenMapAddModal, setIsOpenMapAddModal] = useState(false);
  const [isOpenFilterModal, setIsOpenFilterModal] = useState(false);
  const [filters, setFilters] = useState<Filter[]>([]);
  const [survey, setSurvey] = useState<Survey>();
  const [filteredRespondents, setFilteredRespondents] = useState<
    {
      answers: any;
      meta: any;
      mapMarkers: any;
    }[]
  >([]);
  const [respondents, setRespondents] = useState<
    { answers: any; meta: any; mapMarkers: any }[]
  >([]);
  const [visualizers, setVisualizers] = useImmer<VisualizerFire[]>([]);
  const [nAnswerPoints, setNAnswerPoints] = useState(0);
  const [nMapMarkers, setNMapMarkers] = useState(0);
  const [answerDateData, setAnswerDateData] = useState<
    { date: number; value: number }[]
  >([]);
  const [splitCsvWithOptionColumns, setSplitCsvWithOptionColumns] =
    useState(false);

  const handleOnChangeFlatExport = useCallback(() => {
    setSplitCsvWithOptionColumns((prev) => !prev);
  }, []);

  const handleOnError = useCallback(() => {}, []);

  const handleOnClickReturnPage = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleOnClickAddDataVisualizer = useCallback(
    (type: VISUALIZATION_TYPE) => {
      if (type === VISUALIZATION_TYPE.MAP) {
        setIsOpenMapAddModal(true);
      } else if (type === VISUALIZATION_TYPE.GRAPH) {
        setIsOpenGraphAddModal(true);
      }
    },
    []
  );

  const handleOnClickFilter = useCallback(() => {
    setIsOpenFilterModal(true);
  }, []);

  const handleOnClickAddFilter = useCallback((filter: Filter) => {
    setFilters((prev) => {
      return [...prev, filter];
    });
  }, []);

  const handleOnClickRemoveFilter = useCallback((filter: Filter) => {
    setFilters((prev) => {
      return prev.filter((f) => f !== filter);
    });
  }, []);

  const handleOnCloseFilterModal = useCallback(() => {
    setIsOpenFilterModal(false);
  }, []);

  const handleOnClickAddGraph = useCallback(
    (dataVisualizer: { questionId: string; visualisationType: GRAPH_TYPE }) => {
      setVisualizers((draft) => {
        const newGraphVisualizer: GraphVisualizer = {
          questionId: dataVisualizer.questionId,
          chartType: dataVisualizer.visualisationType,
          size: 12,
          type: VISUALIZATION_TYPE.GRAPH
        };

        draft.push(newGraphVisualizer);
      });

      setIsOpenGraphAddModal(false);
    },
    [setVisualizers]
  );

  const handleOnClickAddMap = useCallback(
    (mapVisualizer: { questionId: string }) => {
      setIsOpenMapAddModal(false);

      setVisualizers((draft) => {
        const newGraphVisualizer: MapVisualizer = {
          questionId: mapVisualizer.questionId,
          size: 12,
          type: VISUALIZATION_TYPE.MAP
        };

        draft.push(newGraphVisualizer);
      });
    },
    [setVisualizers]
  );

  const handleOnCloseGraphAddModal = useCallback(() => {
    setIsOpenGraphAddModal(false);
  }, []);

  const handleOnCloseMapAddModal = useCallback(() => {
    setIsOpenMapAddModal(false);
  }, []);

  const handleOnClickDeleteVisualizer = useCallback(
    (id: string) => {
      setVisualizers((draft) => draft.filter((dv) => dv.questionId !== id));
    },
    [setVisualizers]
  );

  const handleOnClickResizeVisualizer = useCallback(
    (id: string) => {
      setVisualizers((draft) => {
        return draft.map((dv) => {
          if (dv.questionId === id) {
            return {
              ...dv,
              size: dv.size ? (dv.size === 12 ? 6 : 12) : 6
            };
          }
          return dv;
        });
      });
    },
    [setVisualizers]
  );

  const handleOnClickExportGeoJson = useCallback(() => {
    /** Early abort */
    if (!survey || respondents.length === 0) {
      console.error("handleOnClickExportGeoJson: no survey or respondents");
      return false;
    }

    const geojson = convertMarkersToGeoJsonExport(
      respondents
        .map((r) =>
          r.mapMarkers.map((m: any) => ({ ...m, respondentId: r.meta.id }))
        )
        .flat()
    );

    download(
      new Blob([JSON.stringify(geojson)]),
      `mapx-export_${new Date().toISOString().split("T")[0]}_${
        survey.id?.split("/")[0]
      }-${survey.id?.split("/")[1]}.geojson`,
      "application/json"
    );
  }, [respondents, survey]);

  const handleOnClickExportCsv = useCallback(() => {
    /** Early abort */
    if (!survey || respondents.length === 0) {
      console.error("handleOnClickExportCsv: no survey or respondents");
      return false;
    }

    /** Data array */
    const exportData: string[][] = [];

    /** Extract a map of question-full-id -> question-label */
    const mappedSurveyQuestions = mapQuestionsToCsvMap(
      survey,
      splitCsvWithOptionColumns
    );

    /** Push the headers */
    const headerLabels = Array.from(mappedSurveyQuestions.values());
    exportData.push(headerLabels);
    /** Extract header ids */
    const headerIds = Array.from(mappedSurveyQuestions.keys());

    /** Iterate over each respondent and for each header id, find the answer id and push its label */
    for (let i = 0; i < respondents.length; i++) {
      const respondent = respondents[i];

      const mappedRespondentAnswers = mapRespondentAnswers(
        respondent,
        splitCsvWithOptionColumns
      );

      const { meta } = respondent;
      exportData.push(
        headerIds.map((mqk) => {
          if (mappedRespondentAnswers[mqk]) {
            return mappedRespondentAnswers[mqk];
          }
          if (mqk === "responseTimestamp") {
            return meta.created.toDate().toLocaleString();
          }
          if (mqk === "respondentId") {
            return meta.fireId;
          }

          return "null";
        })
      );
    }

    let csvContent = "";
    exportData.forEach((row) => {
      row.forEach((cell) => {
        csvContent += `"${cell}",`;
      });
      csvContent += "\n";
    });

    download(
      new Blob([csvContent]),
      `mapx-export_${new Date().toISOString().split("T")[0]}_${
        survey.id?.split("/")[0]
      }-${survey.id?.split("/")[1]}.csv`,
      "text/csv;charset=utf-8"
    );
  }, [survey, respondents, splitCsvWithOptionColumns]);

  const handleOnClickExportXlsx = useCallback(() => {
    /** Early abort */
    if (!survey || respondents.length === 0) {
      console.error("handleOnClickExportXlsx: no survey or respondents");
      return false;
    }

    /** Data array */
    const exportData: string[][] = [];

    /** Extract a map of question-full-id -> question-label */
    const mappedSurveyQuestions = mapQuestionsToCsvMap(
      survey,
      splitCsvWithOptionColumns
    );

    /** Push the headers */
    const headerLabels = Array.from(mappedSurveyQuestions.values());
    exportData.push(headerLabels);
    /** Extract header ids */
    const headerIds = Array.from(mappedSurveyQuestions.keys());

    /** Iterate over each respondent and for each header id, find the answer id and push its label */
    for (let i = 0; i < respondents.length; i++) {
      const respondent = respondents[i];

      const mappedRespondentAnswers = mapRespondentAnswers(
        respondent,
        splitCsvWithOptionColumns
      );

      const { meta } = respondent;
      exportData.push(
        headerIds.map((mqk) => {
          if (mappedRespondentAnswers[mqk]) {
            return mappedRespondentAnswers[mqk];
          }
          if (mqk === "responseTimestamp") {
            return meta.created.toDate().toLocaleString();
          }
          if (mqk === "respondentId") {
            return meta.fireId;
          }

          return "null";
        })
      );
    }

    var worksheet = utils.aoa_to_sheet(exportData);
    var workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Results");
    writeFileXLSX(
      workbook,
      `mapx-export_${new Date().toISOString().split("T")[0]}_${
        survey.id?.split("/")[0]
      }-${survey.id?.split("/")[1]}.xlsx`
    );
  }, [survey, respondents, splitCsvWithOptionColumns]);

  const handleOnClickSaveDashboard = useCallback(() => {
    const user = fire.getUser();

    if (!user) {
      console.error("handleOnClickSaveDashboard: no user");
      return;
    }
    if (!survey?.fireId) {
      console.error("handleOnClickSaveDashboard: no survey");
      return;
    }

    const dashboard: Dashboard = {
      surveyFireId: survey?.fireId,
      created: Timestamp.now(),
      createdByUid: user.uid,
      edited: Timestamp.now(),
      editedByUid: user.uid,
      name: "default",
      filters,
      visualizers,
      fireId
    };

    fire.saveDashboard(dashboard).then((doc) => {
      if (doc) setFireId(doc.id);
    });
  }, [filters, fireId, survey?.fireId, visualizers]);

  useEffect(() => {
    if (id && shortId) {
      unsubscribeSurveys.current = fire.subscribeToSurvey({
        shortId,
        id,
        onSuccess: (fireSurvey) => {
          setSurvey(fireSurvey);
        },
        onError: (error) => {
          handleOnError();
        }
      });
    }
  }, [handleOnError, id, shortId]);

  useEffect(() => {
    if (id && shortId && survey) {
      unsubscribeAnswers.current = fire.subscribeToAnswers({
        id,
        shortId,
        onSuccess: (fireAnswers) => {
          const answers = convertFireAnswers(fireAnswers, survey);
          setRespondents(answers);
        },
        onError: (error) => {
          handleOnError();
        }
      });
    }
  }, [handleOnError, id, shortId, survey]);

  useEffect(() => {
    const nSubAnswers = filteredRespondents
      .map((answer) => answer.answers.length)
      .reduce((acc, curr) => acc + curr, 0);

    const nSubMapMarkers = filteredRespondents
      .map((answer) => answer.mapMarkers.length)
      .reduce((acc, curr) => acc + curr, 0);

    setNAnswerPoints(nSubAnswers);
    setNMapMarkers(nSubMapMarkers);
  }, [filteredRespondents]);

  useEffect(() => {
    /** Extract firestore created timestamps for each answer */
    const answersDates = filteredRespondents
      .map((answer) => answer.meta.created)
      .map((timestamp: Timestamp) => timestamp.toDate());

    /** Count the number of answers per day */
    const dateData = answersDates
      .reduce((acc, date) => {
        const dateIndex = acc.findIndex((d) => sameDay(d.date, date));

        if (dateIndex === -1) {
          acc.push({ date, value: 1 });
        } else {
          acc[dateIndex].value += 1;
        }

        return acc;
      }, [] as { date: Date; value: number }[])
      .map((d) => ({
        date: d.date.getTime(),
        value: d.value
      }))
      .sort((a, b) => a.date - b.date);

    setAnswerDateData(dateData);
  }, [filteredRespondents]);

  useEffect(() => {
    if (filters.length === 0) {
      setFilteredRespondents(respondents);
      return;
    }

    const filteredRespondents = respondents.filter((r) => {
      return filters.every((f) => {
        const questionResponse = r.answers.find(
          (a: any) => a.question.id === f.questionId
        );
        /** If the question is not answered, return false */
        if (questionResponse === undefined) return false;
        const { answer } = questionResponse;
        if (isNaN(answer)) return false;
        if (f.filterComparison === FILTER_COMPARISON.IS) {
          return answer === f.filterValue;
        }
        if (f.filterComparison === FILTER_COMPARISON.IS_NOT) {
          return answer !== f.filterValue;
        }
        if (f.filterComparison === FILTER_COMPARISON.CONTAINS) {
          return answer.includes(f.filterValue);
        }
        return false;
      });
    });
    setFilteredRespondents(filteredRespondents);
  }, [filters, respondents]);

  useEffect(() => {
    const user = fire.getUser();

    if (!user) {
      console.error("useEffect: no user");
      return;
    }

    if (!survey) {
      console.error("useEffect: no survey");
      return;
    }

    fire
      .getDashboard({
        uid: user.uid,
        surveyFireId: survey?.fireId
      })
      .then((dashboards) => {
        setVisualizers(dashboards?.visualizers || []);
        setFilters(dashboards?.filters || []);
      });
  }, [setVisualizers, survey]);

  return (
    <>
      <AnalyzeViewPage
        surveyTitle={survey?.name}
        surveyId={survey?.id}
        surveyMeta={survey?.meta}
        nAnswerPoints={nAnswerPoints}
        nMapMarkers={nMapMarkers}
        answerDateData={answerDateData}
        onClickExportCsv={handleOnClickExportCsv}
        onClickExportXlsx={handleOnClickExportXlsx}
        onClickExportGeoJson={handleOnClickExportGeoJson}
        onChangeFlatExport={handleOnChangeFlatExport}
        onClickReturnPage={handleOnClickReturnPage}
        onClickAddVisualizer={handleOnClickAddDataVisualizer}
        onClickDeleteVisualizer={handleOnClickDeleteVisualizer}
        onClickResizeVisualizer={handleOnClickResizeVisualizer}
        onClickSaveDashboard={handleOnClickSaveDashboard}
        onClickFilter={handleOnClickFilter}
        visualizers={visualizers}
        nRespondents={filteredRespondents.length}
        filterCount={filters?.length}
        globallyFilteredRespondents={filteredRespondents}
        survey={survey}
      />
      <MapAddModal
        isOpen={isOpenMapAddModal}
        onClose={handleOnCloseMapAddModal}
        onAdd={handleOnClickAddMap}
        survey={survey}
        addedVisualizers={visualizers.filter(
          (v) => v.type === VISUALIZATION_TYPE.MAP
        )}
      />
      <GraphAddModal
        isOpen={isOpenGraphAddModal}
        onClose={handleOnCloseGraphAddModal}
        onAdd={handleOnClickAddGraph}
        survey={survey}
        addedVisualizers={visualizers.filter(
          (v) => v.type === VISUALIZATION_TYPE.GRAPH
        )}
      />
      <FilterModal
        filters={filters}
        onClose={handleOnCloseFilterModal}
        isOpen={isOpenFilterModal}
        onClickAddFilter={handleOnClickAddFilter}
        onRemoveFilter={handleOnClickRemoveFilter}
        survey={survey}
      />
    </>
  );
};

export default AnalyzePage;
