import { useState } from "react";
import NavLayoutProps from "./NavLayout.types";
import NavLayoutView from "./NavLayout.view";
import { useNavigate } from "react-router-dom";

const NavLayout = (props: NavLayoutProps) => {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState("");

  const onClickItem = (props: { id: string; route: string }) => {
    const { id, route } = props;

    setSelectedItem(id);
    navigate(route);
  };

  return (
    <NavLayoutView onClickItem={onClickItem} selectedItem={selectedItem} />
  );
};

export default NavLayout;
