import { Survey } from "interfaces";

export enum GRAPH_TYPE {
  BAR = "stapel",
  LINE = "linje",
  PIE = "cirkel",
}

export enum MAP_TYPE {
  HEATMAP = "heatmap",
  POINTMAP = "pointmap",
}
export interface Data {
  label: string;
  value: number;
}

export interface DataVisualizerProps {
  // data: Data[];
  type?: GRAPH_TYPE;
  graphId?: string;
  onClickDelete?: (id: string) => void;
  onClickResize?: (id: string) => void;
  globallyFilteredRespondents: { answers: any; meta: any }[];
  survey?: Survey;
}
export interface DataVisualizerViewProps {
  graphTitle: string;
  graphId?: string;
  data: Data[];
  type: GRAPH_TYPE;
  onClickDelete: () => void;
  onClickResize: () => void;
}
