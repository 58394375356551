import { Outlet } from "react-router-dom";
import { DrawerListProps, NavLayoutViewProps } from "./NavLayout.types";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import Accordion from "@mui/material/Accordion";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  AccordionDetails,
  AccordionSummary,
  Avatar,
  IconButton,
  Typography
} from "@mui/material";
import { ROUTES } from "components/RouterManager/RouterManager.enums";

const DrawerList = (props: DrawerListProps) => {
  const { onClickItem, selectedItem } = props;

  return (
    <Box sx={{ width: "100%" }} role="presentation">
      <List>
        {[
          {
            text: "Mina formulär",
            icon: <HomeIcon sx={{ width: 32, height: 32 }} />,
            id: "surveys",
            route: ROUTES.DESIGN
          },
          {
            text: "Inställningar",
            icon: <SettingsIcon sx={{ width: 32, height: 32 }} />,
            id: "settings",
            route: ROUTES.SETTINGS
          }
        ].map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton
              onClick={() =>
                onClickItem && onClickItem({ id: item.id, route: item.route })
              }
              selected={selectedItem === item.id}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

const NavLayoutView = (props: NavLayoutViewProps) => {
  const { onClickItem, selectedItem } = props;

  return (
    <Stack direction="row" width="100dvw" height="100dvh">
      <Stack
        minWidth="280px"
        height="100%"
        bgcolor="#dcd9dd"
        position="relative"
      >
        <Stack
          width="100%"
          height="160px"
          alignItems="center"
          justifyContent="center"
        >
          <img
            src="/logo.jpeg"
            alt="logo"
            width="52px"
            height="52px"
            style={{ borderRadius: "12px" }}
          />
          <Typography variant="h6" color="#36494b">
            MapX
          </Typography>
        </Stack>
        <Stack
          width="100%"
          height="120px"
          bgcolor="#dcd9dd"
          justifyContent="center"
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            marginLeft="24px"
          >
            <IconButton>
              <Avatar sx={{ width: 48, height: 48 }} />
            </IconButton>
            <Stack>
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{ userSelect: "none" }}
              >
                John Doe
              </Typography>
              <Typography variant="body2" sx={{ userSelect: "none" }}>
                Medlem
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack width="100%" height="100%" bgcolor="#dcd9dd">
          <DrawerList selectedItem={selectedItem} onClickItem={onClickItem} />
        </Stack>
        <Stack width="100%" position="absolute" bottom={0}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandLessIcon />}>
              Statistik
            </AccordionSummary>
            <AccordionDetails></AccordionDetails>
          </Accordion>
        </Stack>
      </Stack>
      <Outlet />
    </Stack>
  );
};

export default NavLayoutView;
