import { IconButton, Paper, Stack, Typography } from "@mui/material";
import { MapVisualizerViewProps } from "./MapVisualizer.types";
import {
  GeolocateControl,
  Layer,
  Map as MapMapbox,
  MapRef,
  Marker,
  MarkerProps,
  NavigationControl,
  Popup,
  Source
} from "react-map-gl";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Line,
  LineChart,
  Pie,
  PieChart,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { dateFormatter } from "./MapVisualizer.utils";
import DeleteIcon from "@mui/icons-material/Delete";
import AspectRatioICon from "@mui/icons-material/AspectRatio";
import { Map } from "mapbox-gl";
import mapboxgl from "mapbox-gl";

const token =
  "pk.eyJ1IjoiaW5vZGlubyIsImEiOiJjbG5mNWxuN3UwajY4MmtvaHh0dmE1dnBzIn0.3rvwbu7fkYOM9jRMxl7kwA";

const MapVisualizerView = (props: MapVisualizerViewProps) => {
  const { data, graphTitle, onClickDelete, onClickResize } = props;

  return (
    <Paper
      elevation={0}
      sx={{
        margin: 1,
        padding: 2,
        backgroundColor: "rgba(0,0,0,0)",
        outline: "1px solid white",
        color: "white",
        height: "400px"
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          alignItems="flex-start"
          spacing={2}
        >
          <Typography fontSize={18}>{`${graphTitle}`}</Typography>
          <Stack
            bgcolor="rgba(255,255,255,0.1)"
            borderRadius="12px"
            direction="row"
            spacing={0}
          >
            <IconButton onClick={onClickResize}>
              <AspectRatioICon sx={{ color: "white" }} />
            </IconButton>
            <IconButton onClick={onClickDelete}>
              <DeleteIcon sx={{ color: "white" }} />
            </IconButton>
          </Stack>
        </Stack>
        <MapMapbox
          reuseMaps
          mapLib={mapboxgl}
          mapboxAccessToken={token}
          style={{ flex: 1 }}
          mapStyle="mapbox://styles/mapbox/streets-v9"
        >
          <GeolocateControl />
          <NavigationControl />
          <Source id="data" type="geojson" data={data}>
            <Layer
              {...{
                id: "data-layer",
                type: "circle",
                source: "data",
                paint: {
                  "circle-color": "#ff0000"
                }
              }}
            />
          </Source>
        </MapMapbox>
      </Stack>
    </Paper>
  );
};

export default MapVisualizerView;
