import { VISUALIZATION_TYPE } from "enums";
import { Survey, VisualizerFire } from "interfaces";

interface AnalyzeViewPageProps {
  surveyTitle?: string;
  surveyMeta?: Survey["meta"];
  surveyId?: string;
  survey?: Survey;
  nAnswerPoints?: number;
  nRespondents?: number;
  nMapMarkers?: number;
  answerDateData?: { date: number; value: number }[];
  onClickExportCsv?: () => void;
  onClickExportXlsx?: () => void;
  onClickExportGeoJson?: () => void;
  onChangeFlatExport?: () => void;
  onClickAddVisualizer?: (type: VISUALIZATION_TYPE) => void;
  onClickFilter?: () => void;
  filterCount?: number;
  onClickDeleteVisualizer?: (id: string) => void;
  onClickResizeVisualizer?: (id: string) => void;
  onClickReturnPage?: () => void;
  onClickSaveDashboard?: (id?: string) => void;
  visualizers?: VisualizerFire[];
  globallyFilteredRespondents: {
    answers: any;
    meta: any;
    mapMarkers: any;
  }[];
}

export enum FILTER_COMPARISON {
  IS = "is",
  IS_NOT = "is not",
  CONTAINS = "contains"
  // GREATER = "greater",
  // LESS = "less",
}

interface AnalyzePageProps {}

export type { AnalyzeViewPageProps };
export default AnalyzePageProps;
