import { useCallback, useEffect, useState } from "react";
import { MapVisualizerProps } from "./MapVisualizer.types";
import MapVisualizerView from "./MapVisualizer.view";
import { QUESTION_TYPE } from "enums";
import { mapMarkersToGeoJson } from "../AnalyzePage.utils";

const MapVisualizer = (props: MapVisualizerProps) => {
  const {
    onClickDelete,
    onClickResize,
    graphId,
    globallyFilteredRespondents,
    survey
  } = props;

  const [data, setData] = useState<{
    type: string;
    features: any;
  }>();
  const [graphTitle, setGraphTitle] = useState("");

  const handleOnClickDelete = useCallback(() => {
    if (onClickDelete && graphId) {
      onClickDelete(graphId);
    }
  }, [graphId, onClickDelete]);

  const handleOnClickResize = useCallback(() => {
    if (onClickResize && graphId) {
      onClickResize(graphId);
    }
  }, [graphId, onClickResize]);

  useEffect(() => {
    if (!survey) return;
    const { pages } = survey;

    /** Extract all page questions in single array */
    const questions = pages
      .map((page, pageIdx) =>
        page.questions.map((question) => ({ ...question, pageIdx }))
      )
      .flat();

    const graphQuestion = questions.find((q) => q.id === graphId);
    if (!graphQuestion) return;

    const { type, name } = graphQuestion;
    setGraphTitle(name);

    /** Conditionally map the question types */
    if (type === QUESTION_TYPE.MAP_MARKER) {
      const mapData = mapMarkersToGeoJson(
        globallyFilteredRespondents
          .map((respondent) => respondent.mapMarkers)
          .flat()
      );
      setData(mapData);
    }
  }, [globallyFilteredRespondents, graphId, survey]);

  return (
    <MapVisualizerView
      data={data}
      graphId={graphId}
      graphTitle={graphTitle}
      onClickDelete={handleOnClickDelete}
      onClickResize={handleOnClickResize}
    />
  );
};

export default MapVisualizer;
