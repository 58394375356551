import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack
} from "@mui/material";
import MapAddModalProps from "./MapAddModal.types";
import { useCallback, useMemo, useState } from "react";
import { getPossibleMapVisualizers } from "../AnalyzePage.utils";

const MapAddModal = (props: MapAddModalProps) => {
  const { survey, onAdd, onClose, isOpen, addedVisualizers } = props;

  const [selectedQuestionId, setSelectedQuestionId] = useState<string | null>(
    null
  );

  const possibleMapVisualizers = useMemo(() => {
    if (!survey) return [];
    const p = getPossibleMapVisualizers(survey);
    return p;
    /** Extract questions, their possible graph visualization types and the data for each visualisaiton */
  }, [survey]);

  const handleOnChangeSelectedMapId = useCallback((id: string) => {
    setSelectedQuestionId(id);
  }, []);

  const handleOnClickAddMap = useCallback(() => {
    if (possibleMapVisualizers.length === 0 || !selectedQuestionId || !onAdd)
      return;

    const selectedMapVisualizer = possibleMapVisualizers.find(
      (pdv) => pdv.questionId === selectedQuestionId
    );
    if (!selectedMapVisualizer) return;

    const mapVisualizer = {
      questionId: selectedQuestionId,
      questionLabel: selectedMapVisualizer.questionLabel
    };
    onAdd(mapVisualizer);

    setSelectedQuestionId(null);
  }, [onAdd, possibleMapVisualizers, selectedQuestionId]);

  return (
    <Dialog
      className="graph-add-modal"
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
    >
      <DialogContent>
        <Stack spacing={2}>
          <FormControl fullWidth>
            <InputLabel>{"Välj fråga"}</InputLabel>
            <Select
              value={selectedQuestionId}
              onChange={(e) => {
                const { value } = e.target;
                handleOnChangeSelectedMapId(value as string);
              }}
              label={
                possibleMapVisualizers.find(
                  (d) => d.questionId === selectedQuestionId
                )?.questionLabel
              }
              sx={{ backgroundColor: "lightgray" }}
            >
              {possibleMapVisualizers
                .filter(
                  (pdv) =>
                    !addedVisualizers
                      .map((d) => d.questionId)
                      .includes(pdv.questionId)
                )
                .map((x) => (
                  <MenuItem value={x.questionId}>{x.questionLabel}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={onClose}>
          Avbryt
        </Button>
        <Button size="small" onClick={handleOnClickAddMap}>
          Lägg till karta
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MapAddModal;
