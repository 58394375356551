import { useCallback, useEffect, useState } from "react";
import { Data, DataVisualizerProps, GRAPH_TYPE } from "./DataVisualizer.types";
import DataVisualizerView from "./DataVisualizer.view";
import {
  getConditionQuestionResponseCountMap,
  getQuestionResponseCountMap,
} from "../AnalyzePage.utils";
import { QUESTION_TYPE } from "enums";

const DataVisualizer = (props: DataVisualizerProps) => {
  const {
    onClickDelete,
    onClickResize,
    graphId,
    globallyFilteredRespondents,
    survey,
    type: selectedGraphType,
  } = props;

  const [data, setData] = useState<Data[]>([]);
  const [graphTitle, setGraphTitle] = useState("");
  const [graphType, setGraphType] = useState<GRAPH_TYPE>(
    selectedGraphType ?? GRAPH_TYPE.BAR
  );

  useEffect(() => {
    if (!survey) return;

    const { pages } = survey;

    /** Extract all page questions in single array */
    const questions = pages
      .map((page, pageIdx) =>
        page.questions.map((question) => ({ ...question, pageIdx }))
      )
      .flat();
    const graphQuestion = questions.find((q) => q.id === graphId);
    if (!graphQuestion) return;

    const { id, type, name } = graphQuestion;
    setGraphTitle(name);

    /** Conditionally map the question types */
    if (
      type === QUESTION_TYPE.RADIO ||
      type === QUESTION_TYPE.RADIO_MAP ||
      type === QUESTION_TYPE.DROPDOWN ||
      type === QUESTION_TYPE.RADIO_MULTI
    ) {
      const questionDataMap = getQuestionResponseCountMap(
        id,
        globallyFilteredRespondents
      );

      const answerData = Array.from(questionDataMap.entries()).map(
        ([label, value]) => ({
          label,
          value,
        })
      );

      setData(answerData);
      if (!selectedGraphType) {
        setGraphType(GRAPH_TYPE.PIE);
      }
    } else if (
      type === QUESTION_TYPE.RADIO_CONDITION ||
      type === QUESTION_TYPE.DROPDOWN_CONDITION
    ) {
      const questionDataMap = getConditionQuestionResponseCountMap({
        questionId: id,
        conditionQuestionId: graphQuestion.conditionId,
        surveyRespones: globallyFilteredRespondents,
      });

      const answerData = Array.from(questionDataMap.entries()).map(
        ([label, value]) => ({
          label,
          value,
        })
      );

      setData(answerData);
      if (!selectedGraphType) {
        setGraphType(GRAPH_TYPE.BAR);
      }
    }
  }, [globallyFilteredRespondents, graphId, selectedGraphType, survey]);

  const handleOnClickDelete = useCallback(() => {
    if (onClickDelete && graphId) {
      onClickDelete(graphId);
    }
  }, [graphId, onClickDelete]);

  const handleOnClickResize = useCallback(() => {
    if (onClickResize && graphId) {
      onClickResize(graphId);
    }
  }, [graphId, onClickResize]);

  return (
    <DataVisualizerView
      data={data}
      graphId={graphId}
      type={graphType}
      graphTitle={graphTitle}
      onClickDelete={handleOnClickDelete}
      onClickResize={handleOnClickResize}
    />
  );
};

export default DataVisualizer;
